
  import { Component, Vue, VModel } from "vue-property-decorator";
  import DesignModal from "@/components/DesignModal.vue";
  import LzEditorInput from "@/components/EditorInput.vue";

  type FooterProps = WebProps["footer"];

  @Component({ name: "Footer", components: { DesignModal, LzEditorInput } })
  export default class Footer extends Vue {
    @VModel() props!: FooterProps;

    handleUpload(file: File) {
      // TODO: Implement file upload
      // console.log(file);
    }
  }
