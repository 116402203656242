
  import { Component, Vue, VModel } from "vue-property-decorator";
  import DesignModal from "@/components/DesignModal.vue";
  import LayoutSelect from "@/components/LayoutSelect.vue";

  type BookingsProps = WebProps["bookings"];

  @Component({ name: "Bookings", components: { DesignModal, LayoutSelect } })
  export default class Bookings extends Vue {
    @VModel() props!: BookingsProps; // TODO: Map to inputs

    layoutOptions = [
      {
        value: "left",
        label: this.$t("common.design.layout.options.left")
      },
      {
        value: "center",
        label: this.$t("common.design.layout.options.center")
      },
      {
        value: "right",
        label: this.$t("common.design.layout.options.right")
      }
    ];

    validateIfEnabled(validation: string): string {
      return this.props.enabled ? validation : "";
    }
  }
