
  import LzModal from "./Modal.vue";
  import LzButton from "./Button.vue";
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component({ components: { LzModal, LzButton } })
  export default class DesignModal extends Vue {
    @Prop({ type: String, required: true })
    section: string;

    visible = false;
    openModal() {
      this.visible = true;
    }
    closeModal() {
      this.visible = false;
    }
  }
