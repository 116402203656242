
  import { Component, Vue, VModel } from "vue-property-decorator";
  import DesignModal from "@/components/DesignModal.vue";
  import LayoutSelect from "@/components/LayoutSelect.vue";

  type HomepageProps = WebProps["homePage"];

  @Component({ name: "Homepage", components: { DesignModal, LayoutSelect } })
  export default class Homepage extends Vue {
    @VModel() props!: HomepageProps;

    designOptions = [
      {
        value: "left",
        label: this.$t("common.design.layout.options.left")
      },
      {
        value: "right",
        label: this.$t("common.design.layout.options.right")
      },
      {
        value: "center",
        label: this.$t("common.design.layout.options.center")
      },
      {
        value: "left-fill",
        label: this.$t("common.design.layout.options.left-fill")
      },
      {
        value: "right-fill",
        label: this.$t("common.design.layout.options.right-fill")
      }
    ];
  }
