
  import Vue from "vue";
  import { Component, VModel } from "vue-property-decorator";
  import DesignModal from "@/components/DesignModal.vue";

  type ContactProps = WebProps["contact"];

  @Component({
    components: {
      DesignModal
    }
  })
  export default class Contact extends Vue {
    @VModel() props!: ContactProps;
  }
