
  import { Component, Vue, VModel } from "vue-property-decorator";
  import DesignModal from "@/components/DesignModal.vue";
  import LzEditorInput from "@/components/EditorInput.vue";
  import LayoutSelect from "@/components/LayoutSelect.vue";

  type AboutUsProps = WebProps["aboutUs"];

  @Component({
    name: "AboutUs",
    components: { DesignModal, LzEditorInput, LayoutSelect }
  })
  export default class AboutUs extends Vue {
    @VModel() props!: AboutUsProps;

    layoutOptions = [
      {
        value: "left",
        label: this.$t("common.design.layout.options.left")
      },
      {
        value: "right",
        label: this.$t("common.design.layout.options.right")
      }
    ];

    validateIfEnabled(validation: string) {
      return this.props.enabled ? validation : [];
    }
  }
