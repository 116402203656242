
  import { Component, Vue, VModel } from "vue-property-decorator";
  import LzTable from "@/components/Table.vue";
  import LzButton from "@/components/Button.vue";
  import DesignModal from "@/components/DesignModal.vue";

  type TeamProps = WebProps["team"];

  @Component({ name: "Team", components: { LzTable, LzButton, DesignModal } })
  export default class Team extends Vue {
    @VModel() props!: TeamProps;

    validateIfEnabled(validation: string) {
      return this.props.enabled ? validation : [];
    }

    teamForm = {
      id: "",
      picture: null,
      name: "",
      position: "",
      linkedin: ""
    };

    teamFields = [
      { id: "dot", label: this.$t("web.public.teamForm.delete") },
      { id: "name", label: this.$t("web.public.teamForm.name") },
      { id: "position", label: this.$t("web.public.teamForm.position") },
      { id: "linkedin", label: this.$t("web.public.teamForm.linkedin") },
      { id: "delete", label: this.$t("web.public.teamForm.delete") }
    ];

    onTeamSubmit() {
      this.props.members.push({
        id: this.props.members.length + 1,
        picture: this.teamForm.picture,
        name: this.teamForm.name,
        position: this.teamForm.position,
        linkedin: this.teamForm.linkedin
      });

      this.teamForm = {
        id: "",
        picture: null,
        name: "",
        position: "",
        linkedin: ""
      };
    }

    removeTeamMember(e: any) {
      const teamArr = this.props.members;
      const memberIdx = teamArr.findIndex(el => el === e);
      teamArr.splice(memberIdx, 1);
    }
  }
