
  import { Component, Vue, VModel } from "vue-property-decorator";
  import ChooseTemplate from "../ChooseTemplate/index.vue";

  type GeneralProps = WebProps["general"];

  @Component({ name: "General", components: { ChooseTemplate } })
  export default class General extends Vue {
    @VModel() props!: GeneralProps;

    handleChooseTemplate(e: Event & { target: HTMLInputElement }) {
      this.props.templateId = e.target.value;
    }
  }
