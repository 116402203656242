
  import { Vue, Component, VModel } from "vue-property-decorator";

  type StyleProps = WebProps["style"];

  @Component({ name: "Personalize" })
  export default class Personalize extends Vue {
    @VModel() props!: StyleProps;

    fontOptions = {
      roboto: this.$t("web.public.personalizeWebForm.form.fonts.roboto")
      //TODO: uncomment it when the template project is adopted to that feature
      // arial: this.$t("web.public.personalizeWebForm.form.fonts.arial")
    };
  }
